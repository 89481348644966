export default {
    title: "Reading List",
    des: "\"The pen is mightier than the sword.\"",
    book1: "A Clockwork Orange by Anthony Burgess",
    book2: "A Doll's House by Henrik Ibsen",
    book3: "Alice's Adventures in Wonderland by Lewis Carroll",
    book4: "Annapurna by Maurice Herzog",
    book5: "Be Here Now by Ram Dass",
    book6: "Born to Buy by Juliet B. Schor",
    book7: "Common Sense by Thomas Paine",
    book8: "Cracking the Coding Interview by Gayle Laakmann McDowell",
    book9: "Dianetics by L. Ron Hubbard",
    book10: "Fahrenheit 451 by Ray Bradbury",
    book11: "Franny and Zooey by J.D. Salinger",
    book12: "Gulliver's Travels by Johnathan Swift",
    book13: "Ham on Rye by Charles Bukowski",
    book14: "Heart of Darkness by Joseph Conrad",
    book15: "Infinite Jest by David Foster Wallace",
    book16: "Learn Python the Hard Way by Zed Shaw",
    book17: "Lord of the Flies by William Golding",
    book18: "On Writing by Stephen King",
    book19: "One Flew Over the Cuckoo's Nest by Ken Kesey",
    book20: "Republic by Plato",
    book21: "Scott's Last Expedition by Robert Falcon Scott",
    book22: "Ten Days in a Mad-House by Nellie Bly",
    book23: "The Art of War by Sun Tzu",
    book24: "The Catcher in the Rye by J.D. Salinger",
    book25: "The Count of Monte Cristo by Alexander Dumas",
    book26: "The Jungle by Upton Sinclair",
    book27: "This is not a Pipe by Michael Foucalt",
    book28: "Touching My Father's Soul by Jamling Tenzing Norgay",
    book29: "Utopia by Sir Thomas More",
    book30: "You get so Alone Sometimes it Just Makes Sense by Charles Bukowski",
}
